import { GatsbySeo } from 'gatsby-plugin-next-seo'
import RegrasPromocoes from 'src/components/servicos/ServicesPage/RegrasPromocoes'

function Page() {
  return (
    <>
      <GatsbySeo title="Regras de Promoções | Decathlon" noindex nofollow />
      <RegrasPromocoes />
    </>
  )
}

export default Page
